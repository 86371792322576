import { AxiosResponse, AxiosInstance } from 'axios';
import { OrganizerAPI } from '@/apis';
import { VolunteerModel } from '@/models/VolunteerModel';
import { EventWithResumeModel } from '@/models/EventWithResumeModel';
import { ParticipationModel } from '@/models/ParticipationModel';
import { ManagerModel } from '@/models/ManagerModel';
import { TimeSlotModel } from '@/models/TimeSlotModel';
import { CustomAnswerModel } from '@/models/CustomAnswerModel';
import { VolunteerCategoryModel } from '@/models/VolunteerCategoryModel';
import { CampaignModel } from '@/models/CampaignModel';
import { CampaignRegistrationResumeModel } from '@/models/CampaignRegistrationResumeModel';
import { EventModel, EventStatus } from '@/models/EventModel';
import { CampaignAnswersResumeModel } from '@/models/CampaignAnswersResumeModel';
import { CampaignStatus } from '@/models/CampaignModel';
import { ShopTransactionModel } from '@/models/ShopTransactionModel';
import { ShopProductModel } from '@/models/ShopProductModel';
import { ShopOrderModel } from '@/models/ShopOrderModel';


export class VolunteerService {
  private static internal: VolunteerService = new VolunteerService(OrganizerAPI.instance);

  public static get instance(): VolunteerService {
    return VolunteerService.internal;
  }

  constructor(private api: AxiosInstance) { }

  public eventsForManager(email: string, password: string, orgId: string): Promise<EventModel[]> {
    return this.api.get<EventModel[]>(
      '/consult_manager/', {
      params: {
        email,
        password,
        orgId,
      },
    },
    ).then((response: AxiosResponse<EventModel[]>) => {
      return response.data.map((d: any): EventModel => {
        return new EventModel(d);
      });
    });
  }

  public EHRO2ListVolunteersForEvent(organizationId: number, eventId: number): Promise<VolunteerModel[]> {
    return this.api.get<VolunteerModel[]>(
      `/organization/${organizationId}/event/${eventId}/listvolunteers2/`,
    ).then((response: AxiosResponse<VolunteerModel[]>) => response.data.map((d: any): VolunteerModel => {
      const vol = new VolunteerModel(d);
      vol.participations = d.participationsResume.map((res: any): ParticipationModel => {
        const resume = new ParticipationModel(res);
        return resume;
      });
      vol.numberSlots = d.numberSlots;
      return vol;
    }));
  }

  public EHRO2volunteersForOrganization(organizationId: number,
    withCampaignRegistrations = false): Promise<VolunteerModel[]> {
    return this.api.get<VolunteerModel[]>(
      `/organization/${organizationId}/volunteers2/`, {
      params: {
        campaigns: withCampaignRegistrations,
      },
    },
    ).then((response: AxiosResponse<VolunteerModel[]>) => response.data.map((d: any): VolunteerModel => {
      const v = new VolunteerModel(d);

      if (d.campaignRegistrations !== undefined) {
        v.campaignAnswers = d.campaignRegistrations.map((res: any): CampaignAnswersResumeModel => {
          return new CampaignAnswersResumeModel(res);
        });
      }

      return v;
    }));
  }

  public EHRO2volunteersForOrganizationWithCampaignAnswer(organizationId: number): Promise<VolunteerModel[]> {
    throw new Error('REMOVED BY SEPHI !  EHRO-284');
  }

  public EHRO2VolunteerDetail(organizationId: number, volunteerId: number): Promise<VolunteerModel> {
    return this.api.get<VolunteerModel>(
      `/organization/${organizationId}/volunteers2/${volunteerId}/`,
    ).then((response: AxiosResponse<VolunteerModel>) => new VolunteerModel(response.data));
  }

  public EHRO2retrieveVolunteer(organizationId: number, volunteerId: number): Promise<VolunteerModel> {
    return this.api.get<VolunteerModel>(
      `/organization/${organizationId}/completeVolunteer2/${volunteerId}/`,
    ).then((response: AxiosResponse<any>) => {
      return this.buildVolunteerWithResume(response.data);
    });
  }

  public EHRO2retrieveVolunteerForEvent(eventId: number, volunteerId: number): Promise<VolunteerModel> {
    return this.api.get<VolunteerModel>(
      `/event/${eventId}/completeVolunteer2/${volunteerId}/`,
    ).then((response: AxiosResponse<any>) => this.buildVolunteerWithResume(response.data));
  }

  public createShiftAndRegistration(
    volunteer: VolunteerModel,
    positionId: number,
    sendConfirmation: boolean,
    nbParticipants: number = 1,
    groupName: string = '',
    customAnswers: CustomAnswerModel[] = [],
    startTime: Date,
    endTime: Date,
  ): Promise<any> {
    return this.api.post<any>(`/participations-with-time-slot/`, {
      volunteer: volunteer.toJSON(),
      customAnswers,
      sendConfirmation,
      nbParticipants: Number(nbParticipants),
      groupName,
      position: positionId,
      startTime,
      endTime,
    }).then((response: AxiosResponse<any>) => response.data).catch((error: any) => {
      throw error;
    });
  }

  public EHRO3MakeRegistration(
    volunteer: VolunteerModel,
    timeSlotId: number,
    sendConfirmation: boolean,
    nbParticipants: number = 1,
    groupName: string = '',
    customAnswers: CustomAnswerModel[] = [],
    patisserie: string = '',
    referenceMemberFirstName: string = '',
    referenceMemberLastName: string = '',
    key: string | undefined | null = null,
  ): Promise<any> {

    let url = `/volunteers/participations/`;
    if (key) {
      url += `?passkey=${key}`;
    }

    return this.api.post<any>(
      url, {
      volunteer: volunteer.toJSON(),
      timeSlot: timeSlotId,
      sendConfirmation,
      nbParticipants: Number(nbParticipants),
      groupName,
      customAnswers,
      patisserie,
      referenceMemberFirstName,
      referenceMemberLastName,
    },
    ).then((response: AxiosResponse<any>) => response.data).catch((error: any) => {
      throw error;
    });
  }

  public EHRO3UpdateParticipationAnswers(
    participation: ParticipationModel,
  ): Promise<any> {

    return this.api.put<any>(
      `/volunteers/participations/${participation.id}/`, {
      nbParticipants: Number(participation.nbParticipants),
      groupName: participation.groupName,
      customAnswers: participation.customAnswers,
      sendConfirmation: false,
    },
    ).then((response: AxiosResponse<any>) => response.data).catch((error: any) => {
      throw error;
    });
  }

  public deleteVolunteerParticipation(partID: number, tokenString: string): Promise<any> {
    return this.api.delete<any>(
      `/volunteers/participations/${partID}/`, {
      params: {
        token: tokenString,
      },
    },
    ).then((response: AxiosResponse<any>) => response.data).catch((error: any) => {
      throw error;
    });
  }


  public EHRO2CreateVolunteerInOrg(orgId: number, volunteer: VolunteerModel): Promise<VolunteerModel> {
    return this.api.post<VolunteerModel>(
      `/organization/${orgId}/volunteers2/`,
      volunteer.toJSON(),
    ).then((response: AxiosResponse<VolunteerModel>) => new VolunteerModel(response.data));
  }

  public bulkUpdateVolunteerCategories(vols: VolunteerModel[], categIds: number[]): Promise<any> {
    const volsIds = vols.map((vol: VolunteerModel): any => {
      return vol.id;
    });
    return this.api.post<any>(
      '/volunteers/bulk_update/', {
      volunteers: volsIds,
      categories: categIds,
    },
    ).then((response: AxiosResponse<any>) => response.data);
  }

  public deleteEHRO2Volunteer(orgId: number, volunteer: VolunteerModel): Promise<VolunteerModel> {
    return this.api.delete(
      `/organization/${orgId}/volunteers2/${volunteer.id}/`,
    ).then((response: AxiosResponse) => volunteer);
  }

  public EHRO2retrieveParticipations(eventId: number): Promise<ParticipationModel[]> {
    return this.api.get<ParticipationModel[]>(
      `/events/${eventId}/participations2/`,
    ).then((response: AxiosResponse<ParticipationModel[]>) => {
      return response.data.map((d: any): ParticipationModel => new ParticipationModel(d));
    });
  }

  public EHRO2retrieveParticipationsManager(eventId: number, manager: ManagerModel): Promise<ParticipationModel[]> {
    return this.api.get<ParticipationModel[]>(
      `/events/${eventId}/participations2/`, {
      params: {
        email: manager.email,
        password: manager.password,
      },
    },
    ).then((response: AxiosResponse<ParticipationModel[]>) => {
      return response.data.map((d: any): ParticipationModel => new ParticipationModel(d));
    });
  }

  public updateEHRO2Participation(eventId: number, part: ParticipationModel): Promise<ParticipationModel> {
    return this.api.put<ParticipationModel>(
      `/events/${eventId}/participations2/${part.id}/`,
      part,
    ).then((response: AxiosResponse<ParticipationModel>) => new ParticipationModel(response.data));
  }

  public EHRO2UpdateParticipationDetail(
    partId: number,
    refFirstName: string,
    refLastName: string,
    patisserie: string,
    free1: string,
    free2: string,
  ): Promise<AxiosResponse> {
    return this.api.post(
      `/participationUpdate/${partId}/update_part_detail/`, {
      refFirstName,
      refLastName,
      patisserie,
      free1,
      free2,
    },
    ).then((response: AxiosResponse) => response);
  }

  public EHRO2ChangeParticipationVol(
    partId: number,
    volId: number,
  ): Promise<AxiosResponse> {
    return this.api.post(
      `/participationUpdate/${partId}/change_volunteer/`, {
      volunteer_id: volId,
    },
    ).then((response: AxiosResponse) => response);
  }

  public deleteEHRO2Participation(
    eventId: number,
    partId: number,
  ): Promise<AxiosResponse> {
    return this.api.delete(
      `/events/${eventId}/participations2/${partId}/`,
    ).then((response: AxiosResponse) => response);
  }

  public EHRO2BatchParticipations(
    vols: VolunteerModel[],
    slotId: number | null,
    participationId: number | null,
    recurring: boolean = false): Promise<any> {
    const batch = vols.map((vol: VolunteerModel): any => {
      return ({ volunteer: vol.id, timeSlot: slotId, participation: participationId, recurring });
    });
    return this.api.post<any>(
      '/participation/bulk/',
      batch,
    ).then((response: AxiosResponse<any>) => response.data);
  }


  public retrieveCategories(organizationId: number): Promise<VolunteerCategoryModel[]> {
    return this.api.get<VolunteerCategoryModel[]>(`/organization/${organizationId}/volunteercategories/`, {
    }).then((response: AxiosResponse<VolunteerCategoryModel[]>) => {
      return response.data.map((d: any): VolunteerCategoryModel => new VolunteerCategoryModel(d));
    });
  }

  public createCategory(categ: VolunteerCategoryModel): Promise<VolunteerCategoryModel> {
    return this.api.post<VolunteerCategoryModel>(`/organization/${categ.organizationId}/volunteercategories/`,
      categ.toJSON()).then((response: AxiosResponse<VolunteerCategoryModel>) => {
        return new VolunteerCategoryModel(response.data);
      });
  }

  public updateCategory(categ: VolunteerCategoryModel): Promise<VolunteerCategoryModel> {
    return this.api.put<VolunteerCategoryModel>(
      `/organization/${categ.organizationId}/volunteercategories/${categ.id}/`,
      categ.toJSON(),
    ).then((response: AxiosResponse<VolunteerCategoryModel>) => new VolunteerCategoryModel(response.data));
  }

  public deleteCategory(categ: VolunteerCategoryModel): Promise<VolunteerCategoryModel> {
    return this.api.delete(
      `/organization/${categ.organizationId}/volunteercategories/${categ.id}/`,
    ).then((response: AxiosResponse) => categ);
  }



  public retrieveVolunteerByEmail(orgId: number, email: string): Promise<VolunteerModel[]> {
    return this.api.get<VolunteerModel[]>(
      `/organization/${orgId}/publicvolunteers/`, {
      params: {
        email,
      },
    },
    ).then((response: AxiosResponse<VolunteerModel[]>) => response.data.map((d: any): VolunteerModel => {
      return new VolunteerModel(d);
    }));
  }

  public EHRO2retrieveParticipationsByToken(token: string): Promise<any> {
    return this.api.get<VolunteerModel>(
      `/volunteers/by-token/${token}/participations/`,
    ).then((response: AxiosResponse<any>) => {
      const vol = new VolunteerModel(response.data.volunteer);
      const events = this.buildEventsWithAssignments(response.data.events);
      return {
        volunteer: vol,
        events,
      };
    });
  }

  public retrieveRegistrationsByToken(token: string): Promise<any> {
    return this.api.get<VolunteerModel>(
      `/volunteers/by-token/${token}/campaign-registrations/`,
    ).then((response: AxiosResponse<any>) => {
      const answers: any[] = [];
      response.data.forEach((d: any) => {
        const cmp = new CampaignModel(d.campaign);
        if (cmp.status === CampaignStatus.Published) {
          answers.push({
            regId: d.id,
            campaign: cmp,
            createdAt: d.createdAt,
          });
        }
      });
      return answers;
    });
  }

  public retrieveProductsByToken(token: string): Promise<ShopProductModel[]> {
    return this.api.get<ShopProductModel[]>(`/volunteers/by-token/${token}/products/`, {
    }).then((response: AxiosResponse<ShopProductModel[]>) => {
      return response.data.map((d: any): ShopProductModel => new ShopProductModel(d));
    });
  }
  public retrieveOrdersByToken(token: string): Promise<any> {
    return this.api.get<ShopOrderModel[]>(`/volunteers/by-token/${token}/orders/`, {
    }).then((response: AxiosResponse<ShopOrderModel[]>) => {
      return response.data.map((d: any): ShopOrderModel => new ShopOrderModel(d));
    });
  }
  public makeOrderByToken(token: string, productID: number, quantity: number): Promise<any> {
    return this.api.post<ShopProductModel[]>(`/volunteers/by-token/${token}/orders/`, {
      product: productID,
      quantity,
    }).then((response: AxiosResponse<any>) => {
      return response.data;
    });
  }

  public retrieveRegistrationsDetailByToken(token: string, regId: string): Promise<any> {
    return this.api.get<VolunteerModel>(
      `/volunteers/by-token/${token}/campaign-registrations/${regId}`,
    ).then((response: AxiosResponse<any>) => {
      return response.data;
    });
  }

  public retrieveVolunteerWithToken(tokenString: string): Promise<any> {
    return this.api.get<VolunteerModel>(
      `/volunteers/participations/`, {
      params: {
        token: tokenString,
      },
    },
    ).then((response: AxiosResponse<any>) => {
      const vol = new VolunteerModel(response.data.volunteer);
      const events = this.buildEventsWithAssignments(response.data.events);

      return {
        volunteer: vol,
        events,
      };
    });
  }

  public retreivePointsTransaction(token: string): Promise<ShopTransactionModel[]> {
    return this.api.get<ShopTransactionModel[]>(
      `/volunteers/by-token/${token}/points-transactions/`
    ).then((response: AxiosResponse<ShopTransactionModel[]>) => {
      return response.data.map((d: any): ShopTransactionModel => new ShopTransactionModel(d));
    });
  }

  public claimVouchers(token: string): Promise<any> {
    return this.api.post<VolunteerModel>(
      `/volunteers/by-token/${token}/vouchers/claim/`
    ).then((response: AxiosResponse<any>) => {
      return response.data;
    });
  }

  public login(organizationId: number, email: string): Promise<void> {
    return this.api.post('/volunteers/login/', {
      organization: organizationId, email,
    });
  }

  private buildVolunteerWithResume(responseData: any): VolunteerModel {
    const vol = new VolunteerModel(responseData);
    vol.participations = responseData.participationsResume.map((res: any): ParticipationModel => {
      return new ParticipationModel(res);
    });

    vol.numberSlots = responseData.numberSlots;
    vol.campaignResume = responseData.campaignRegistrations.map((res: any): CampaignRegistrationResumeModel => {
      return new CampaignRegistrationResumeModel(res);
    });
    return vol;
  }

  private buildEventsWithAssignments(responseData: any): EventWithResumeModel[] {
    return responseData.map((res: any): EventWithResumeModel => {
      const ev = new EventWithResumeModel(res);
      ev.populateSlots(res.participations);
      return ev;
    });
  }
}
