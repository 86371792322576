import axios, { AxiosResponse, AxiosInstance } from 'axios';
import { OrganizerAPI } from '@/apis';
import { EventModel } from '@/models/EventModel';
import { TagModel } from '@/models/TagModel';
import { OrganizationModel } from '@/models/OrganizationModel';
import { UserModel } from '@/models/UserModel';
import { EventDayModel } from '@/models/EventDayModel';
import { CustomFieldModel } from '@/models/CustomFieldModel';
import { TimeSlotModel } from '@/models/TimeSlotModel';
import { ParticipationsRankingModel } from '@/models/ParticipationsRankingModel';
import { DateTime } from 'luxon';
import { VolunteerCategoryModel } from '@/models/VolunteerCategoryModel';
import { PositionModel } from '@/models/PositionModel';
import { RewardCategoryModel } from '@/models/RewardCategoryModel';
import { ShopProductModel } from '@/models/ShopProductModel';


export class EventService {

  private static internal: EventService = new EventService(OrganizerAPI.instance);

  public static get instance(): EventService {
    return EventService.internal;
  }

  constructor(private api: AxiosInstance) { }

  public fcvgStats(organizationId: number): Promise<any> {
    return this.api.get<OrganizationModel[]>('/fcvg_stats/', {
      params: {
        organizationId,
      },
    }).then((response: AxiosResponse<any>) => {
      return response;
    });
  }

  public retreiveDashboardInformations(organizationId: number, yearsArray: string[]): Promise<OrganizationModel[]> {
    return this.api.get<OrganizationModel[]>('/dashboard/', {
      params: {
        organizationId,
        years: yearsArray,
      },
    }).then((response: AxiosResponse<any>) => {
      let orgs: OrganizationModel[] = [];
      orgs = response.data.map((d: any): OrganizationModel => {
        const org = new OrganizationModel(d);
        org.latestRegistration = d.latestRegistration;
        org.eventsResume = d.eventsResume;
        org.nbrVolunteers = d.nbrVolunteers;
        return org;
      });
      return orgs;
    });
  }

  public retreivePublicEvents(organizationId: number): Promise<EventModel[]> {
    return this.api.get<EventModel[]>(`/organization/${organizationId}/public-events/`, {
    }).then((response: AxiosResponse<EventModel[]>) => {
      let events: EventModel[] = [];
      events = response.data.map((d: any): EventModel => {
        const ev = new EventModel(d);
        ev.organizationName = d.organizationName;
        ev.nbrVolunteersRegistered = d.nbrVolunteersRegistered;
        ev.nbrVolunteersRequired = d.nbrVolunteersRequired;
        return ev;
      });
      return events;
    });
  }

  public retrieveEvents(organizationId: number): Promise<EventModel[]> {
    return this.api.get<EventModel[]>('/events/', {
      params: {
        organizationId,
      },
    }).then((response: AxiosResponse<EventModel[]>) => {
      return response.data.map((d: any): EventModel => new EventModel(d));
    });
  }

  public retrievePublishedEvents(organizationId: number): Promise<EventModel[]> {
    return this.api.get<EventModel[]>('/events/', {
      params: {
        organizationId,
        status: 'published',
      },
    }).then((response: AxiosResponse<EventModel[]>) => {
      return response.data.map((d: any): EventModel => new EventModel(d));
    });
  }

  public retrieveListEvents(organizationId: number): Promise<EventModel[]> {
    return this.api.get<EventModel[]>('/listEvent/', {
      params: {
        organizationId,
      },
    }).then((response: AxiosResponse<EventModel[]>) => {
      let events: EventModel[] = [];
      events = response.data.map((d: any): EventModel => {
        const ev = new EventModel(d);
        ev.organizationName = d.organizationName;
        ev.nbrVolunteersRegistered = d.nbrVolunteersRegistered;
        ev.nbrVolunteersRequired = d.nbrVolunteersRequired;
        return ev;
      });
      return events;
    });
  }

  public retrieveOrganizations(): Promise<OrganizationModel[]> {
    return this.api.get<OrganizationModel[]>('/organization/', {
    }).then((response: AxiosResponse<OrganizationModel[]>) => {
      return response.data.map((d: any): OrganizationModel => new OrganizationModel(d));
    });
  }

  public retreiveTags(): Promise<TagModel[]> {
    return this.api.get<TagModel[]>('/tags/', {
    }).then((response: AxiosResponse<TagModel[]>) => {
      return response.data.map((d: any): TagModel => new TagModel(d));
    });
  }


  public createPosition(position: PositionModel, eventID: number): Promise<PositionModel> {
    let body = position.toJSON();
    body.event_id = eventID;
    return this.api.post<PositionModel>(`/positions/`,
      body).then((response: AxiosResponse<PositionModel>) => {
        return new PositionModel(response.data);
      });
  }
  public updatePosition(position: PositionModel, eventID: number): Promise<PositionModel> {
    let body = position.toJSON();
    body.event_id = eventID;
    return this.api.put<PositionModel>(`/positions/${position.id}/`,
      body).then((response: AxiosResponse<PositionModel>) => {
        return position;
      });
  }
  public deletePosition(position: PositionModel): Promise<PositionModel> {
    return this.api.delete<PositionModel>(`/positions/${position.id}/`).then((response: AxiosResponse<PositionModel>) => {
      return position;
    });
  }

  public retrieveProducts(organizationId: number): Promise<ShopProductModel[]> {
    return this.api.get<ShopProductModel[]>(`/organization/${organizationId}/products/`, {
    }).then((response: AxiosResponse<ShopProductModel[]>) => {
      return response.data.map((d: any): ShopProductModel => new ShopProductModel(d));
    });
  }


  public retrieveCustomFields(organizationId: number): Promise<CustomFieldModel[]> {
    return this.api.get<CustomFieldModel[]>(`/organization/${organizationId}/customfields/`, {
    }).then((response: AxiosResponse<CustomFieldModel[]>) => {
      return response.data.map((d: any): CustomFieldModel => new CustomFieldModel(d));
    });
  }

  public cloneEvent(
    event: EventModel,
    newName: string,
    startDay: EventDayModel,
    cloneParticipations: boolean,
    categories: number[]): Promise<any> {

    let body: any = {
      newName,
      startDay,
      cloneParticipations,
    };

    if (categories.length > 0) {
      body = {
        newName,
        startDay,
        cloneParticipations,
        cloneOnlyParticipationsInCategories: categories,
      };
    }

    return this.api.post('/events/' + event.id + '/clone/', body).then((response: AxiosResponse) => {
      console.log('[CloningEvent] response : ' + response);
      return response;
    }).catch((error) => {
      console.log('[CloningEvent] error : ' + error);
      throw error;
    });
  }

  public extendEvent(
    event: EventModel,
    targetEvent: EventModel,
    startDay: string,
    cloneParticipations: boolean,
    cloneOnlyParticipationsInCategories: number[]): Promise<any> {

    let body: any = {
      startDay,
      targetEvent: targetEvent.id,
      cloneParticipations,
      cloneOnlyParticipationsInCategories,
    };

    return this.api.post('/events/' + event.id + '/copy_into/', body).then((response: AxiosResponse) => {
      return response;
    }).catch((error) => {
      throw error;
    });
  }


  public retrieveListEventsWithStatus(organizationId: number | null, status: string): Promise<EventModel[]> {
    return this.api.get<EventModel[]>('/listEvent/', {
      params: {
        organizationId,
        status,
      },
    }).then((response: AxiosResponse<any>) => {
      let events: EventModel[] = [];
      events = response.data.map((d: any): EventModel => {
        const ev = new EventModel(d);
        ev.organizationName = d.organizationName;
        ev.nbrVolunteersRegistered = d.nbrVolunteersRegistered;
        ev.nbrVolunteersRequired = d.nbrVolunteersRequired;
        return ev;
      });
      return events;
    });
  }


  public createCustomFields(organizationId: number, field: CustomFieldModel): Promise<CustomFieldModel> {
    return this.api.post<CustomFieldModel>(`/organization/${organizationId}/customfields/`,
      field.toJSON()).then((response: AxiosResponse<CustomFieldModel>) => {
        return new CustomFieldModel(response.data);
      });
  }

  public updateCustomFields(organizationId: number, field: CustomFieldModel): Promise<CustomFieldModel> {
    return this.api.put<CustomFieldModel>(`/organization/${organizationId}/customfields/${field.id}/`,
      field.toJSON()).then((response: AxiosResponse<CustomFieldModel>) => {
        return new CustomFieldModel(response.data);
      });
  }

  public deleteCustomFields(organizationId: number, field: CustomFieldModel): Promise<AxiosResponse> {
    return this.api.delete<CustomFieldModel>(`/organization/${organizationId}/customfields/${field.id}/`
    ).then((response: AxiosResponse) => {
      return response;
    }).catch((error) => {
      throw error;
    });
  }

  public createSlot(event: EventModel, slot: TimeSlotModel): Promise<TimeSlotModel[]> {
    return this.api.post<TimeSlotModel>(`/slots/`, slot.toJSON()).then((response: AxiosResponse<any>) => {
      let data = response.data;
      let slots: TimeSlotModel[] = [];

      if (!Array.isArray(response.data)) {
        const slot = new TimeSlotModel(response.data);
        slot.startTime = DateTime.fromJSDate(data.startTime).setZone(event.timeZone);
        slot.endTime = DateTime.fromJSDate(data.endTime).setZone(event.timeZone);
        slots.push(slot);
      } else {
        slots = response.data.map((d: any): TimeSlotModel => {
          const slot = new TimeSlotModel(d);
          slot.startTime = DateTime.fromJSDate(d.startTime).setZone(event.timeZone);
          slot.endTime = DateTime.fromJSDate(d.endTime).setZone(event.timeZone);
          return slot;
        });
      }
      return slots;
    });
  }
  public updateSlot(event: EventModel, slot: TimeSlotModel): Promise<TimeSlotModel> {
    return this.api.put<TimeSlotModel>(`/slots/${slot.id}/`,
      slot.toJSON()).then((response: AxiosResponse<any>) => {

        let data = response.data;
        data.startTime = DateTime.fromJSDate(data.startTime).setZone(event.timeZone);
        data.endTime = DateTime.fromJSDate(data.endTime).setZone(event.timeZone);

        return new TimeSlotModel(response.data);
      });
  }
  public deleteSlot(slot: TimeSlotModel): Promise<TimeSlotModel> {
    return this.api.delete(`/slots/${slot.id}/`).then((response: AxiosResponse) => {
      return slot;
    });
  }


  public updateSlotSeries(event: EventModel, slot: TimeSlotModel): Promise<TimeSlotModel[]> {
    return this.api.put<TimeSlotModel>(`/slots-series/${slot.series}/time_slots/`,
      slot.toJSON()).then((response: AxiosResponse<any>) => {
        const slots = response.data.map((d: any): TimeSlotModel => {
          const slot = new TimeSlotModel(d);
          slot.startTime = DateTime.fromJSDate(d.startTime).setZone(event.timeZone);
          slot.endTime = DateTime.fromJSDate(d.endTime).setZone(event.timeZone);
          return slot;
        });
        return slots;
      });
  }

  public deleteSlotSeries(slot: TimeSlotModel): Promise<TimeSlotModel> {
    return this.api.delete(`/slots-series/${slot.series}/`).then((response: AxiosResponse) => {
      return slot;
    });
  }



  public createEvent(event: EventModel): Promise<EventModel> {
    return this.api.post<EventModel>(
      `/events/`,
      event.toJSON()).then((response: AxiosResponse<EventModel>) => {
        return new EventModel(response.data);
      });
  }

  public updateEvent(event: EventModel): Promise<EventModel> {
    return this.api.put<EventModel>(
      `/events/${event.id}/`,
      event.toJSON()).then((response: AxiosResponse<EventModel>) => {
        return new EventModel(response.data);
      });
  }

  public partialUpdateEvent(eventId: number, body: any): Promise<void> {
    return this.api.patch<EventModel>(
      `/events/${eventId}/`,
      body).then((response: AxiosResponse) => {
        return response.data;
      });
  }

  public updateStatusEvent(event: EventModel): Promise<AxiosResponse> {
    return this.api.put(
      `/updateEventsStatus/${event.id}/`,
      event.toJSON()).then((response: AxiosResponse) => {
        return response;
      });
  }

  public retrieveEvent(eventId: number): Promise<EventModel> {
    return this.api.get<EventModel>(`/events/${eventId}/`).then((response: AxiosResponse<EventModel>) => {
      const ev = new EventModel(response.data);
      ev.organizationName = response.data.organizationName;
      ev.nbrVolunteersRegistered = response.data.nbrVolunteersRegistered;
      ev.nbrVolunteersRequired = response.data.nbrVolunteersRequired;
      return ev;
    });
  }

  public retrieveEventByUUID(eventUUID: string, key: string | null = null, secret: string | null = null): Promise<EventModel> {
    let url = `/publicevent/${eventUUID}/`;
    if (key) {
      url += `?passkey=${key}`;
    }

    let param = {};
    if (secret) {
      param = {
        params: {
          s: secret
        }
      };
    }

    return this.api.get<EventModel>(url, param).then((response: AxiosResponse<EventModel>) => {
      return new EventModel(response.data);
    }).catch((error) => {
      console.log('[EventService] error : ' + error);
      throw error;
    });
  }

  public retrieveEventManagerByUUID(eventUUID: string, email: string, password: string, orgId: string): Promise<EventModel> {
    return this.api.get<EventModel>(`/publicevent/${eventUUID}/`, {
      params: {
        email,
        password,
        orgId,
      }
    }).then((response: AxiosResponse<EventModel>) => {
      return new EventModel(response.data);
    }).catch((error) => {
      console.log('[EventService] error : ' + error);
      throw error;
    });
  }


  public deleteEvent(event: EventModel): Promise<EventModel> {
    return this.api.delete(`/events/${event.id}/`).then((response: AxiosResponse) => {
      return event;
    });
  }

  public deleteTESTEvent(id: string): Promise<string> {
    return this.api.delete(`/events/${id}/`).then((response: AxiosResponse) => {
      return 'plop';
    });
  }

  public retrieveParticipationsRankings(): Promise<ParticipationsRankingModel[]> {
    return this.api.get<ParticipationsRankingModel[]>('/participations-rankings/').then(
      (response: AxiosResponse<ParticipationsRankingModel[]>) => {
        return response.data.map((d: any): ParticipationsRankingModel => new ParticipationsRankingModel(d));
      });
  }

  public retreiveRestriction(eventID: string) {
    return this.api.get(`/custom/registration-rules/${eventID}/`).then((response: AxiosResponse) => {
      return response.data;
    });
  }

  public postRestriction(eventID: string, categoryID: string) {
    return this.api.post('/custom/registration-rules/', {
      event: eventID,
      category: categoryID,
    }).then((response: AxiosResponse) => {
      return response.data;
    });
  }

  public cancelSlots(eventID: string, slotsID: string[], email: string, password: string, orgId: string) {
    return this.api.post(`/events/${eventID}/cancel_slots/`, {
      slots: slotsID,
      email,
      password,
      orgId,
    }).then((response: AxiosResponse) => {
      return response.data;
    });
  }


  // REWARD
  public refreshRewardCategories(eventID: number): Promise<RewardCategoryModel[]> {
    return this.api.get<RewardCategoryModel[]>(`/events/${eventID}/reward_categories/`, {
    }).then((response: AxiosResponse<RewardCategoryModel[]>) => {
      return response.data.map((d: any): RewardCategoryModel => new RewardCategoryModel(d));
    });
  }

  public retreivePublicRewardsCategories(eventUUID: string): Promise<RewardCategoryModel[]> {
    return this.api.get<RewardCategoryModel[]>(`/publicevent/${eventUUID}/reward_categories/`, {
    }).then((response: AxiosResponse<RewardCategoryModel[]>) => {
      return response.data.map((d: any): RewardCategoryModel => new RewardCategoryModel(d));
    });
  }

  // FFG
  public retrievePrivateLinks(eventId: number): Promise<any> {
    return this.api.get<EventModel>(`/events/${eventId}/tag_passkeys/`).then((response: AxiosResponse<any>) => {
      return response;
    });
  }

}
